* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Tùy chỉnh font và margin cho thẻ body */
body {
  font-family: Arial, sans-serif;
}
.report_container {
  width: 100%;
  height: 100vh;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
}
/* Định dạng phần form login */
.login-form {
  width: 512px;
  margin: auto;
  border: 1px solid #ccc;
  padding: 60px 46px 48px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px rgba(153, 160, 178, 0.05);
  border-radius: 8px;
}

/* Định dạng tiêu đề */
h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 10px;
}
.text_form {
  text-align: center;
  font-size: 16px;
  color: #636e72;
}

/* Định dạng nhãn và input */
.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
input::placeholder {
  color: #a3a3a3;
  font-size: 14px;
}

/* Định dạng nút đăng nhập */
.button_login {
  width: 100%;
  padding: 12px;
  background-color: #32a1da;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.button_logout {
  width: 168px;
  padding: 12px;
  background-color: #32a1da;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.header {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Hiệu ứng hover cho nút đăng nhập */
.button_login:hover {
  opacity: 0.8;
}
.button_logout:hover {
  opacity: 0.8;
}
.report {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.report_header {
  margin-bottom: 12px;
}
.report_title {
  font-size: 24px;
  margin-bottom: 12px;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 48px;
}
.custom-table th {
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
}
.custom-table td {
  border: 1px solid #000;
  padding: 16px;
  text-align: left;
}
.custom-table th {
  font-size: 24px;
}
.custom-table td {
  font-size: 20px;
}

.custom-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
